export default () => [
  // base propbar
  {
    from: new Date('15 September 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  // 50% off sale
  {
    from: new Date('25 September 2024 00:00:00 UTC'),
    content: {
      text: 'Up to 50% off sale! To shop, please',
      link: '/list/shop-all-tu-clothing-sale?tag=tu:propbar',
      colour: 'red',
    },
  },
  // base propbar
  {
    from: new Date('01 October 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
